
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from "react-router-dom";

const LoginRedirect = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    if (isAuthenticated) {
        navigate("/tenants")
    } else {
        instance.loginRedirect({
            scopes: ["User.Read"],
            extraScopesToConsent: ["api://2b7aea1d-3c63-41fa-a257-7425d8439698/Usage"]
        })
    }
    return (<Container></Container>)
}

export default LoginRedirect;
