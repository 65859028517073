import { Container } from "react-bootstrap";
import LoginButton from "./login_button";
import LogoutButton from "./logout_button";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

const Profile = () => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    return (
        <div>
            {isAuthenticated && account && (
                <Container>
                    <div>
                        <h5>{account.name ?? account.username}</h5>
                        <LogoutButton />
                    </div>
                </Container>
            )}

            {!isAuthenticated && (
                <LoginButton />
            )}

        </div>
    );
};

export default Profile;