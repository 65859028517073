import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';

const LogoutButton = () => {
    const { instance, } = useMsal();

    return (
        <Button variant="outline-secondary"
            onClick={() => instance.logout({ postLogoutRedirectUri: window.location.origin })}
        >
            Log Out
        </Button >
    );
};

export default LogoutButton;