import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Card, Spinner, Toast } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Backend } from '../App';
import { setErrorMessage, startLoading, stopLoading } from '../models/nav';
import { ITenant, updateTenants } from '../models/tenant';
import { RootState } from '../store';
import GetStarted from "./get_started";
import TenantCreation from './tenant_creation';

const Tenants = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [selectedTenantIndex, setSelectedTenantIndex] = useState(0);

    const tenants = useSelector((state: RootState) => state.tenants);
    const dispatch = useDispatch();
    const navData = useSelector((state: RootState) => state.navData);
    // TODO(thomas): that depends on the entitlements from the subscription
    const allowedToCreateTenants = false;

    // this effect needs to stay here as the ControlCenter is the actual component being loaded
    useEffect(() => {
        if (!account) {
            instance.loginRedirect();
            return
        }

        dispatch(startLoading());
        instance.acquireTokenSilent({
            scopes: ["api://2b7aea1d-3c63-41fa-a257-7425d8439698/Usage"],
            account: account || undefined
        }).then((response) => {
            if (response) {
                fetch(`${Backend}/v1/tenant`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + response.accessToken,
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => {
                        dispatch(stopLoading());
                        if (!response.ok) {
                            if (response.status === 401) {
                                return new Error("Unauthorized");
                            }
                            return response.json().then(e => new Error(e["message"]))
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data instanceof Error) {
                            throw data;
                        }

                        // pivot this back into an array for react
                        const tx: ITenant[] = data.tenants.map((d: any) => d as ITenant);
                        dispatch(updateTenants(tx))
                        dispatch(stopLoading());
                    }).catch(e => {
                        console.log(e);
                        dispatch(setErrorMessage(`${e.message} - could not retrieve tenants, please try again later.`))
                    })
            }
        });
    }, [account, account?.username, dispatch, instance]);

    return (
        <Container>
            <Row>
                <Row>
                    <Container>
                        {navData.errorMessage && navData.errorMessage !== "" &&
                            <Toast
                                bg="danger"
                                onClose={() => dispatch(setErrorMessage(""))}                                >
                                <Toast.Body className="text-white">{navData.errorMessage}</Toast.Body>
                            </Toast>
                        }
                    </Container>
                </Row>

                <Col md="auto">
                    {allowedToCreateTenants && <TenantCreation />}

                    {navData.loading &&
                        <Row className="row-loading-spinner">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Row>
                    }
                    {!navData.loading &&
                        tenants.tenants.map(function (v: ITenant, i: number) {
                            return <Row key={"trow/" + v.id}>
                                <Card key={"tcard/" + v.id}
                                    className={i === selectedTenantIndex ? "selected-tenant" : ""}
                                    bg={i === selectedTenantIndex ? "success" : "light"}
                                    text={i === selectedTenantIndex ? "light" : "dark"}
                                    onClick={() => setSelectedTenantIndex(i)} >
                                    <Card.Body>
                                        <Card.Title>{v.name ?? v.id}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Row>
                        })
                    }

                </Col>

                <Col>
                    {tenants.tenants.length > 0 &&
                        <GetStarted tenant={tenants.tenants[selectedTenantIndex]} />
                    }
                    {!navData.loading && tenants.tenants.length === 0 &&
                        <h2>No tenants or active subscriptions found, please contact support</h2>
                    }
                </Col>
            </Row>
        </Container >
    );
}

export default Tenants;
