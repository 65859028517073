import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { Row, Spinner, Toast } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';
import { Backend } from '../App';
import { setErrorMessage, startLoading, stopLoading } from '../models/nav';
import { RootState } from '../store';

export interface SubscriptionState {
    saasSubscriptionStatus: string,
}

const Register = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const urlParams = new URLSearchParams(window.location.search);
    let marketplaceToken = urlParams.get("token");
    // we need to set this in local storage, so it doesn't get lost in redirect loops
    localStorage.setItem("marketplace-token", marketplaceToken ?? "")
    marketplaceToken = localStorage.getItem("marketplace-token");

    const dispatch = useDispatch();
    const navData = useSelector((state: RootState) => state.navData);

    useEffect(() => {
        if (isAuthenticated) {
            return;
        }

        dispatch(startLoading());
        instance.initialize().then(async () => {
            if (!account) {
                await instance.loginRedirect();
                return
            }

            const tokenResponse = await instance.acquireTokenSilent({
                scopes: ["api://2b7aea1d-3c63-41fa-a257-7425d8439698/Usage"],
                account: account || undefined
            });
            if (tokenResponse) {
                try {
                    const fetchResult = await fetch(`${Backend}/v1/subscription/landing?token=${marketplaceToken}`,
                        {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json'
                            }
                        });

                    if (!fetchResult.ok) {
                        if (fetchResult.status === 401) {
                            return new Error("Unauthorized");
                        }
                        return fetchResult.json().then(e => new Error(e["message"]))
                    }
                    // TODO it's not a return, it's a setState
                    return fetchResult.json();
                } catch (e: any) {
                    console.log(e);
                    dispatch(setErrorMessage(`${e.message} - could not retrieve subscriptions, please try again later.`))
                } finally {
                    dispatch(stopLoading());
                }
            }
        });
    }, [instance, isAuthenticated, marketplaceToken, account, dispatch]);

    return (
        <Container>
            <Row>
                {!isAuthenticated && <Container>Please login to your Microsoft account.</Container>}
            </Row>
            <Row>
                <Container>
                    {navData.errorMessage && navData.errorMessage !== "" &&
                        <Toast
                            onClose={() => dispatch(setErrorMessage(""))}>
                            <Toast.Body className="text-white">{navData.errorMessage}</Toast.Body>
                        </Toast>
                    }
                </Container>
            </Row>

            {navData.loading &&
                <Row className="row-loading-spinner">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row>
            }
        </Container >
    );
}

export default Register;
