import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ITenant {
  id: string;
  name: string;
  tokens: string[];
  dataModelUrl: string;
  subscription: string;
  planId: string;
}

export interface ITenants {
  tenants: ITenant[];
}

export const initialTenantState: ITenants = {
  tenants: [],
};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialTenantState,
  reducers: {
    updateTenants: (state, action: PayloadAction<ITenant[]>) => {
      state.tenants = action.payload;
    },
    cleanTenants: (state) => {
      state.tenants = [];
    },
    createTenant: (state, action: PayloadAction<ITenant>) => {
      state.tenants = [...state.tenants, action.payload];
    },
  },
});

export const { updateTenants, createTenant, cleanTenants } =
  tenantsSlice.actions;
