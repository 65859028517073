import { useIsAuthenticated } from "@azure/msal-react";

import Container from 'react-bootstrap/Container';
import Tenants from "./tenants";

const Landing = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <Container>
            {(!isAuthenticated) &&
                <Container>
                    <h1 className="header">
                        Say goodbye to messy data
                    </h1>
                    <h3 className="header muted-text">
                        Where data entry becomes effortless, fast, and secure - straight from Excel.
                    </h3>
                </Container>
            }
            {(isAuthenticated) &&
                <Tenants />

            }
        </Container >
    );
}

export default Landing;
