import {
    configureStore
} from "@reduxjs/toolkit";
import { navDataSlice } from "./models/nav";
import {
    initialTenantState,
    tenantsSlice
} from "./models/tenant";

const defaultPreloadState = {
  tenants: initialTenantState,
};

export const store = configureStore({
  devTools: false,
  preloadedState: defaultPreloadState,
  reducer: {
    tenants: tenantsSlice.reducer,
    navData: navDataSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
