import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import App from './App';
import './index.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./components/landing";
import LoginRedirect from "./components/login_redirect";
import Register from "./components/register";
import Tenants from "./components/tenants";

const configuration: Configuration = {
  auth: {
    clientId: "2b7aea1d-3c63-41fa-a257-7425d8439698"
  }
};
const store = require("./store").store;
const pca = new PublicClientApplication(configuration);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="" element={<Landing />} />
              <Route path="login" element={<LoginRedirect />} />
              <Route path="tenants" element={<Tenants />} />
              <Route path="register" element={<Register />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </MsalProvider>
);

