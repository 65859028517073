import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Backend } from '../App';
import { ITenant } from '../models/tenant';

const Swagger = ({ tenant }: { tenant: ITenant }) => {
    const [openAPIDefinition, setOpenAPIDefinition] = useState(null);
    const [loading, setLoading] = useState(true);

    // this effect needs to stay here as the ControlCenter is the actual component being loaded
    useEffect(() => {
        setLoading(true);

        fetch(`${Backend}/v1/collect`,
            {
                method: 'GET',
                headers: {
                    'x-cdmwtf-key': tenant.tokens[0],
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                setLoading(false);
                if (!response.ok) {
                    return response.json().then(e => new Error(e["message"]))
                }
                return response.json();
            })
            .then(data => {
                if (data instanceof Error) {
                    throw data;
                }

                // reset some definitions coming out of the swagger UI
                delete data.info;
                delete data.externalDocs;
                data.schemes = ["https"];
                data.host = "api.cdm.wtf";
                data.basePath = "/v1/collect";
                data.consumes = ["application/json"];
                data.produces = ["application/json"];
                delete data.paths["/"]

                let defKeys = Object.keys(data.definitions)
                for (let i = 0; i < defKeys.length; i++) {
                    let o = JSON.parse(data.definitions[defKeys[i]].description);
                    data.definitions[defKeys[i]].description = o.cdmDescription;
                    let propKeys = Object.keys(data.definitions[defKeys[i]].properties)
                    for (let j = 0; j < propKeys.length; j++) {
                        let d = data.definitions[defKeys[i]].properties[propKeys[j]].description;
                        const pgrestIndex = d.indexOf("\n\n");
                        if (pgrestIndex >= 0) {
                            d = d.substring(0, pgrestIndex);
                        }
                        let op = JSON.parse(d);
                        data.definitions[defKeys[i]].properties[propKeys[j]].description = op.cdmDescription;
                    }
                }

                let pathKeys = Object.keys(data.paths)
                for (let i = 0; i < pathKeys.length; i++) {
                    let propKeys = Object.keys(data.paths[pathKeys[i]])
                    for (let j = 0; j < propKeys.length; j++) {
                        let op = JSON.parse(data.paths[pathKeys[i]][propKeys[j]].summary);
                        data.paths[pathKeys[i]][propKeys[j]].summary = op.cdmDescription;
                    }
                }

                let paramKeys = Object.keys(data.parameters)
                for (let i = 0; i < paramKeys.length; i++) {
                    try {
                        let op = JSON.parse(data.parameters[paramKeys[i]].description);
                        data.parameters[paramKeys[i]].description = op.cdmDescription;
                    } catch (e) {
                        // big no-no, but can't be bothered to fix the parsing in the shitty swagger file                
                    }
                }

                data.securityDefinitions = {
                    "APIKeyHeader": {
                        type: "apiKey",
                        in: "header",
                        name: 'x-cdmwtf-key'
                    }
                }
                data.security = [{ "APIKeyHeader": [] }]

                setOpenAPIDefinition(data)
                setLoading(false);
            }).catch(e => {
                console.log(e);
            })
        // eslint-disable-next-line
    }, [tenant.id]);

    const complete = function (swaggerUi: any) {
        swaggerUi.preauthorizeApiKey("APIKeyHeader", tenant.tokens[0]);
    };

    return (
        <Container>
            {loading &&
                <Row className="row-loading-spinner">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row>
            }
            {!loading &&
                openAPIDefinition != null &&
                <SwaggerUI spec={openAPIDefinition} onComplete={complete} />
            }
        </Container >
    );
}

export default Swagger;
