import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link, Outlet } from "react-router-dom";
import './App.css';
import mainLogo from './assets/logo_large.png';
import Profile from './components/profile';

// export const Backend = "http://localhost:5001/cdm-wtf/europe-west4/tenant"
export const Backend = "https://api.cdm.wtf"


function App() {
  return (
    <div className="App">
      <Container className="p-3">
        <Row className='nav-row'>
          <Col className='logo'>
            <Link to='/'>
              <img src={mainLogo} className="logo-svg" alt='cdm wtf logo' />
            </Link>
          </Col>
          <Col className='connected'>
            <Profile />
          </Col>
        </Row>
      </Container>
      <Container>
        <Outlet context={{}} />
      </Container>
      <Container>
        <footer className="bg-theme-light dark:bg-darkmode-theme-light">
          <Row>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </footer>
      </Container>
    </div>
  );
}

export default App;
