import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';

const LoginButton = () => {
    const { instance } = useMsal();

    return <Button variant="outline-primary"
        onClick={() => instance.loginRedirect(
            {
                scopes: ["User.Read"],
                extraScopesToConsent: ["api://2b7aea1d-3c63-41fa-a257-7425d8439698/Usage"]
            }
        )}
    >Log In</Button>;
};

export default LoginButton;